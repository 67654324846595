import { useContext, useEffect } from 'react';
import {
  USER_AUTH_LIFECYCLE_ACTIONS,
  QUESTS_ACTIONS,
  AppContext,
} from './appContext';
import { useCatalogQuests } from './wilco-engine/queries/quest';
import { useUserData } from './wilco-engine/queries/user';

const DataLoader = () => {
  const { dispatch } = useContext(AppContext);
  const updatedUser = useUserData();
  const fetchedCatalogQuests = useCatalogQuests();

  useEffect(() => {
    if (updatedUser?.data) {
      dispatch({
        type: USER_AUTH_LIFECYCLE_ACTIONS.UPDATE_USER,
        payload: updatedUser.data,
      });
    }
  }, [dispatch, updatedUser?.data]);

  useEffect(() => {
    if (fetchedCatalogQuests?.data) {
      dispatch({
        type: QUESTS_ACTIONS.SET_CATALOG_QUESTS,
        payload: fetchedCatalogQuests.data,
      });
    }
  }, [dispatch, fetchedCatalogQuests?.data]);

  return null;
};

export default DataLoader;
