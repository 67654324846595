import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import engineClient from '../../engine-client';
import {
  GeneratedQuestSessionResponse,
  GeneratedQuestResponseWithId,
  GenerateQuestRequest,
  GeneratedQuestDownload,
} from './types';
import { AxiosError } from 'axios';

export const QUERIES_KEYS = {
  GENERATED_QUESTS: ['generatedQuests'],
  IN_REVIEW_QUESTS: ['inReviewQuests'],
};

const updateQuestsOnSuccess = (
  newQuest: GeneratedQuestSessionResponse,
  queryClient: QueryClient
) => {
  queryClient.setQueryData(
    ['generatedQuests'],
    (
      oldQuests: GeneratedQuestSessionResponse[] | undefined
    ): GeneratedQuestSessionResponse[] => {
      return oldQuests ? [newQuest, ...oldQuests] : [newQuest];
    }
  );
};

export function useGenerateQuestMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (generateQuestRequest: GenerateQuestRequest) =>
      engineClient.post<GeneratedQuestSessionResponse>(
        '/genai/quests',
        generateQuestRequest
      ),
    onSuccess: result => updateQuestsOnSuccess(result.data, queryClient),
  });
}

export function useGeneratedQuestsQuery() {
  return useQuery<GeneratedQuestResponseWithId[]>({
    queryKey: QUERIES_KEYS.GENERATED_QUESTS,
    queryFn: async () => {
      const { data } = await engineClient.get<GeneratedQuestResponseWithId[]>(
        '/genai/quests',
        {
          params: {
            limit: 10,
          },
        }
      );
      return data;
    },
  });
}

export function useGeneratedQuestDownloadQuery(id: string) {
  return useQuery<GeneratedQuestDownload, AxiosError>({
    queryKey: ['generatedQuestDownload', id],
    queryFn: async () => {
      const { data } = await engineClient.get(`/quests/${id}/download`);
      return data;
    },
    enabled: false,
  });
}

export function useInReviewQuery() {
  return useQuery({
    queryKey: QUERIES_KEYS.IN_REVIEW_QUESTS,
    queryFn: async () => {
      const { data } = await engineClient.get('/quests/inReview');
      return data;
    },
  });
}
