import { useQuery } from '@tanstack/react-query';
import engineClient from '../engine-client';

export const QUEST_QUERIES_KEYS = {
  CATALOG_QUESTS: ['catalogQuests'],
};

export function useCatalogQuests() {
  return useQuery({
    queryKey: QUEST_QUERIES_KEYS.CATALOG_QUESTS,

    queryFn: async () => {
      const quests = await engineClient.getQuestsForCatalog();
      return quests;
    },
  });
}

export function useQuestByPrimaryOrQuestIdQuery(questPrimaryOrId) {
  return useQuery({
    queryKey: ['questByPrimaryOrQuestId', questPrimaryOrId],

    queryFn: async () => {
      return await engineClient.getQuestByPrimaryOrId(questPrimaryOrId);
    },
  });
}

export function useJourneyByCompanyAndIdQuery(company, journey) {
  return useQuery({
    queryKey: ['journeyByCompanyAndId', journey, company],

    queryFn: async () => {
      if (!company || !journey) return null;
      return await engineClient.getCompanyJourneyDataById(company, journey);
    },
  });
}

export function useQuestCredits(questPrimaryId) {
  return useQuery({
    queryKey: ['questCredits', questPrimaryId],

    queryFn: async () => {
      const questCredits = await engineClient.getQuestCredits(questPrimaryId);
      return questCredits;
    },
  });
}

export function useQuestHintsUsed(questPrimaryId) {
  return useQuery({
    queryKey: ['questHintsUsed', questPrimaryId],

    queryFn: async () => {
      const questCredits = await engineClient.getQuestHintsUsed(questPrimaryId);
      return questCredits;
    },
  });
}

export function useCompanyQuestsIdsQuery(companyName) {
  return useQuery({
    queryKey: ['companyQuestsIds', companyName],

    queryFn: async () => {
      const questsIds = await engineClient.getCompanyQuestsIds(companyName);
      return questsIds;
    },
  });
}
