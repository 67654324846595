import { useQuery } from '@tanstack/react-query';
import engineClient from '../engine-client';

export const USER_QUERIES_KEYS = {
  USER_QUESTS: ['userQuests'],
  CODESPACE_STATUS: ['myCodespaceStatus'],
  MY_AUTHORED_QUESTS: ['myAuthoredQuests'],
  USER_DATA: ['userData'],
  USER_NOTIFICATIONS: ['userNotifications'],
};

export function useChallengesWithUserStatusQuery(userId, userAchievements) {
  return useQuery({
    queryKey: ['challenges', userId, userAchievements],

    queryFn: async () => {
      return await engineClient.getChallengesWithUserStatus();
    },
  });
}

export function useUserQuestCertificateQuery(questPrimaryId) {
  return useQuery({
    queryKey: ['userQuestCertificate', questPrimaryId],

    queryFn: async () => {
      const data = await engineClient.getOrCreateUserQuestCertificate(
        questPrimaryId
      );
      return data;
    },
  });
}

export function useUserAchievementsQuery(userId) {
  return useQuery({
    queryKey: ['userAchievements', userId],

    queryFn: async () => {
      return await engineClient.getUserAchievements();
    },
  });
}

export function useUserQuestsQuery(withCertificates = false) {
  return useQuery({
    queryKey: USER_QUERIES_KEYS.USER_QUESTS,

    queryFn: async () => {
      const data = await engineClient.getUserQuests(withCertificates);
      return data;
    },
  });
}

export function useGithubConnect(code) {
  return useQuery({
    queryKey: ['githubConnect', code],

    queryFn: async () => {
      const data = await engineClient.connectGithubApp(code);
      return data;
    },
  });
}

export function useWixConnect(code) {
  return useQuery({
    queryKey: ['wixConnect', code],

    queryFn: async () => {
      const data = await engineClient.connectWixApp(code);
      return data;
    },
  });
}

export function useWixUserConnect(wixUserId) {
  return useQuery({
    queryKey: ['wixUserConnect', wixUserId],

    queryFn: async () => {
      const data = await engineClient.connectWixUser(wixUserId);
      return data;
    },
  });
}

export function useUiPathConnect(code) {
  return useQuery({
    queryKey: ['uiPathConnect', code],

    queryFn: async () => {
      const data = await engineClient.connectUiPathApp(code);
      return data;
    },
  });
}

export function useMyCodespace() {
  return useQuery({
    queryKey: ['myCodespace'],

    queryFn: async () => {
      const data = await engineClient.myCodespace();
      return data;
    },
  });
}

export function useMyCodespaceStatus(enabled = true) {
  return useQuery({
    queryKey: ['myCodespaceStatus'],

    queryFn: async () => {
      const data = await engineClient.myCodespaceStatus();
      return data;
    },
    enabled: enabled,
  });
}

export function useMyAuthoredQuests() {
  return useQuery({
    queryKey: ['myAuthoredQuests'],

    queryFn: async () => {
      const data = await engineClient.getUserAuthoredQuests();
      return data;
    },
  });
}

export function useStartingBot() {
  return useQuery({
    queryKey: ['startingBot'],

    queryFn: async () => {
      const data = await engineClient.getStartingBot();
      return data;
    },
  });
}
export function useUserData() {
  return useQuery({
    queryKey: USER_QUERIES_KEYS.USER_DATA,
    queryFn: async () => {
      const { data } = await engineClient.get(`/users/me`);
      return data;
    },
  });
}

export function useUserNotifications(active = true) {
  return useQuery({
    queryKey: USER_QUERIES_KEYS.USER_NOTIFICATIONS,
    queryFn: async () => {
      const { data } = await engineClient.get(`/users/me/notifications`, {
        params: { active: active },
      });
      return data;
    },
  });
}
