import { useContext, useEffect } from 'react';
import { AppContext, SOCKET_ACTIONS } from './appContext';
import { io } from 'socket.io-client';
import { auth } from './auth/AuthService';

const SocketInitializer = () => {
  const {
    dispatch,
    state: { user, socket },
  } = useContext(AppContext);

  useEffect(() => {
    if (user && !socket) {
      const options = {
        transports: ['websocket'],
        query: { token: auth.getAccessToken(), userId: user.id },
      };
      const newSocket = io(import.meta.env.VITE_ENGINE_URL, options);

      dispatch({
        type: SOCKET_ACTIONS.SET_SOCKET,
        payload: { socket: newSocket },
      });
    }
  }, [user, dispatch, socket]);

  return null;
};

export default SocketInitializer;
